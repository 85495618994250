import React, { useContext } from "react";
import { AuthContext } from "../context/AuthContext";

const Logout = ({ onClick }) => {
  const { auth } = useContext(AuthContext);

  return (
    <div className="d-flex align-items-center justify-content-end">
      <button className="btn" onClick={onClick}>
        <span className="pe-2 text-white" style={{ fontSize: 13 }}>
          {auth.fullname.split(" ").length >= 2
            ? `${auth.fullname.split(" ")[0]} ${auth.fullname.split(" ")[1]}`
            : auth.fullname.split(" ")[0]}
        </span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="#FFF"
          className="bi bi-box-arrow-right"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"
          />
          <path
            fillRule="evenodd"
            d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"
          />
        </svg>
      </button>
    </div>
  );
};

export default Logout;
