import React from "react";

const FloatMenu = ({ show, onClick }) => {
  if (show)
    return (
      <button
        onClick={onClick}
        className="tss_btn_float_menu btn btn-primary shadow"
      >
        <span style={{ fontSize: 13 }} className="font-weight-bold text-white">
          Menú
        </span>
      </button>
    );
  else return null;
};

export default FloatMenu;
