import React, { useContext } from "react";
import { ChatContext } from "../context/ChatContext";
import { Status } from "./components";

const Header = () => {
  const { state } = useContext(ChatContext);
  return (
    <div className="tss_chat_header bg-light border-bottom">
      <div className="w-100 d-flex flex-column align-items-start justify-content-center">
        <p className="mb-0" style={{ fontSize: 14 }}>
          {state.to ? state.to.fullname : ""}
        </p>
        <Status
          online={state.to.online}
          writing={state.writing || false}
          username={state.to ? state.to.fullname?.split(" ")[0] : ""}
        />
      </div>
    </div>
  );
};

export default Header;
