import { Keys } from "./config";
import CryptoJS from "crypto-js";

const KEY = Keys.ket_storage;
const SKEY = "Tss!#$%&/()=?JEñ.ss!#$%&/()=?JEñ.";

// TOKEN
const save_token = (token) => {
  localStorage.setItem(KEY, CryptoJS.AES.encrypt(token, SKEY).toString());
  return true;
};

const remove_token = () => {
  localStorage.removeItem(KEY);
  return true;
};

const get_token = () => {
  try {
    let value = localStorage.getItem(KEY);
    let h = null;
    if (value)
      h = CryptoJS.AES.decrypt(value, SKEY).toString(CryptoJS.enc.Utf8);
    return h;
  } catch (err) {
    remove_token();
    return false;
  }
};

export { save_token, remove_token, get_token };
