import React, { useContext, useEffect, useRef } from "react";
import { ChatContext } from "../context/ChatContext";
import ScrollToBottom from "react-scroll-to-bottom";
import Message from "./Messages";

const Body = ({ handlerClickTypeButton }) => {
  const { state } = useContext(ChatContext);
  const bottomRef = useRef(null);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({behavior: 'smooth'});
  }, [state.messages]);
  return (
    <div className="tss_chat_body">
      <ScrollToBottom initialSticky={false} className="h-100 overflow-auto">
        {state.messages?.map((value, index) => (
          <Message
            key={index}
            value={value}
            handlerClickTypeButton={handlerClickTypeButton}
          />
        ))}
        <div ref={bottomRef} />
      </ScrollToBottom>
    </div>
  );
};

export default Body;
