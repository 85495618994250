import { types } from "./types";

export const authReducer = (state, action) => {
  switch (action.type) {
    case types.LOGIN:
      return {
        ...state,
        ...action.payload,
      };
    case types.LOGOUT:
      return {
        id: null,
        fullname: null,
        email: null,
        profile: null,
        room: null,
        logged: false,
      };
    default:
      return state;
  }
};
