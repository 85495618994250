import axios from "axios";
import { get_token } from "../storage";
import { Services as ServiceConfig } from "../config";

class Api {
  request = null;

  constructor(_token = null) {
    const token = _token ? _token : get_token();
    this.request = axios.create({
      baseURL: ServiceConfig.Api,
      timeout: ServiceConfig.time_request,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Api-key": `${token}`,
      },
    });
  }

  login = async (username, password, areaId) => {
    try {
      return await this.request.post("auth/login", {
        username,
        password,
        areaId,
      });
    } catch (error) {
      if (error.response) throw error.response;
      else throw error;
    }
  };

  requestAuth = async (uid, fullname, area_id, email, phone_number) => {
    try {
      return await this.request.post("auth/request/login", {
        uid,
        fullname,
        area_id,
        email,
        phone_number,
      });
    } catch (error) {
      if (error.response) throw error.response;
      else throw error;
    }
  };

  get_areas = async () => {
    try {
      return await this.request.get("auth/areas");
    } catch (error) {
      if (error.response) throw error.response;
      else throw error;
    }
  };

  profile = async () => {
    try {
      return await this.request.get("auth/profile");
    } catch (error) {
      if (error.response) throw error.response;
      else throw error;
    }
  };

  requestUser = async (uid, fullname, password, email, phone_number) => {
    try {
      return await this.request.post("auth/request", {
        uid,
        fullname,
        password,
        email,
        phone_number,
      });
    } catch (error) {
      if (error.response) throw error.response;
      else throw error;
    }
  };

  // Watson assistant
  watson_assistant_request_session = async () => {
    try {
      return await this.request.post(`watson/assistant/request-session`);
    } catch (error) {
      if (error.response) throw error.response;
      else throw error;
    }
  };

  watson_assistant_init = async () => {
    try {
      return await this.request.post(`watson/assistant/create-session`);
    } catch (error) {
      if (error.response) throw error.response;
      else throw error;
    }
  };

  watson_assistant_message = async (sessionId, body) => {
    try {
      return await this.request.post(`watson/assistant/messages`, {
        sessionId,
        body,
      });
    } catch (error) {
      if (error.response) throw error.response;
      else throw error;
    }
  };

  // Room
  get_room = async (area_id) => {
    try {
      return await this.request.get(`rooms/${area_id}`);
    } catch (error) {
      if (error.response) throw error.response;
      else throw error;
    }
  };

  put_room_transfer = async (area_id) => {
    try {
      return await this.request.put(`rooms/user/transfer/${area_id}`);
    } catch (error) {
      if (error.response) throw error.response;
      else throw error;
    }
  };

  close_room = async () => {
    try {
      return await this.request.delete(`user/rooms/close`);
    } catch (error) {
      if (error.response) throw error.response;
      else throw error;
    }
  };

  // Messages
  save_message = async (roomId, fromModel, from, type, body, read) => {
    try {
      return await this.request.post(`messages`, {
        roomId,
        fromModel,
        from,
        type,
        body,
        read,
      });
    } catch (error) {
      if (error.response) throw error.response;
      else throw error;
    }
  };

  // Survey
  save_survey = async (score, message, comment) => {
    try {
      return await this.request.post(`surveys`, {
        score,
        message,
        comment,
      });
    } catch (error) {
      if (error.response) throw error.response;
      else throw error;
    }
  };
}

export default Api;
