import React, { useEffect } from "react";
import { Services } from "../../config";

const RedirectAgent = () => {
  useEffect(() => {
    window.location.href = Services.redirectAgent;
  }, []);

  return (
    <div className="w-100 h-100 d-flex justify-content-center align-items-center">
      <div
        className="spinner-border text-secondary"
        style={{ width: "3rem", height: "3rem" }}
        role="status"
      >
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};

export default RedirectAgent;
