import React from "react";

const Buttons = ({ message, handlerClickTypeButton }) => {
  const items = message.body.split(",");
  return (
    <div className="d-flex flex-wrap align-items-stretch">
      {items.map((value, index) => (
        <button
          key={index}
          onClick={() => handlerClickTypeButton(value)}
          className="btn btn-outline-primary text-center flex-fill m-1"
        >
          <span>{value}</span>
        </button>
      ))}
    </div>
  );
};

export default Buttons;
