import React from "react";

const AlertMessage = ({ type, show, children }) => {
  if (show)
    return (
      <div className={`alert alert-${type}`} role="alert">
        {children}
      </div>
    );
  else return null;
};

export default AlertMessage;
