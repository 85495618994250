import React from "react";

const Status = ({ online, username, writing }) => {
  return (
    <span className="text-muted lh-1" style={{ fontSize: 13 }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="7"
        height="7"
        fill={` ${online ? "var(--bs-success)" : "var(--bs-danger)"}`}
        className="bi bi-circle-fill"
        viewBox="0 0 16 16"
      >
        <circle cx="8" cy="8" r="8" />
      </svg>
      &nbsp;
      <span style={{ fontSize: 12 }}>
        {writing ? (
          `${username} esta escribiendo ...`
        ) : (
          <>{online ? "Conectado" : "Desconectado"}</>
        )}
      </span>
    </span>
  );
};

export default Status;
