import React from "react";

const Video = ({ message }) => {
  return (
    <video
      width="100%"
      className="rounded shadow-sm"
      controls
      style={{ outline: "none" }}
    >
      <source src={message.body} type="video/mp4" />
    </video>
  );
};

export default Video;
