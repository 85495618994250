export const types = {
  TEXT: "text",
  IMAGE: "image",
  VIDEO: "video",
  BUTTONS: "buttons",
  FILE: "file",
  LINK: "link",
  LIST: "list",
  LISTS: "lists",
  HTML: "html",
};
