import React, { useContext } from "react";
import { Services as ServiceConfig } from "../../../../../config";
import { ChatContext } from "../../../../context/ChatContext";
import { get_token } from "../../../../../storage";
import { types } from "../../types";
import axios from "axios";

const File = ({ message }) => {
  const { state } = useContext(ChatContext);

  const dowload = (event, name) => {
    event.preventDefault();
    axios({
      method: "GET",
      url: `${ServiceConfig.Api}/room/${state.room.id}/messages/file/download/${name}`,
      headers: {
        "api-key": get_token(),
      },
      responseType: "blob",
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        // if (
        //   `${
        //     document.querySelector('meta[name="RN_GLOBAL_WEBVIEW"]')?.content
        //   }` !== "true"
        // )
        //   link.setAttribute("download", name);
        link.setAttribute("download", name);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const ext = message.body.slice(
    ((message.body.lastIndexOf(".") - 1) >>> 0) + 2
  );

  if (ext.split("/")[1] === "embed")
    return (
      <iframe
        title={Date.now()}
        src={message.body}
        width="350"
        height="370"
        frameBorder="0"
        allowFullScreen
        webkitallowfullscreen="true"
        msallowfullscreen="true"
      ></iframe>
    );
  else {
    if (message.status === types.WAITTING) return <span>{message.body}</span>;
    else {
      return (
        <>
          <a
            href="/"
            onClick={(event) => dowload(event, message.body)}
            className="d-flex align-items-center justify-content-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-file-earmark-arrow-down"
              viewBox="0 0 16 16"
            >
              <path d="M8.5 6.5a.5.5 0 0 0-1 0v3.793L6.354 9.146a.5.5 0 1 0-.708.708l2 2a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 10.293V6.5z" />
              <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
            </svg>
            &nbsp;&nbsp;{message.body}
          </a>
        </>
      );
    }
  }
};

export default File;
