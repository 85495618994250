import React from "react";

const Link = ({ message }) => {
  const body = JSON.parse(message.body);
  return (
    <a href={body.source} target="_blank" rel="noopener noreferrer">
      {body.name}
    </a>
  );
};

export default Link;
