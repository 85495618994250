import React, { useContext, useEffect, useState, useRef } from "react";
import { AuthContext } from "../context/AuthContext";
import { SocketContext } from "../context/SocketContext";
import { Emojis, InputFile } from "./components";
import { Button as ButtonEmoji } from "./components/Emojis";

const Footer = ({ showEmoji, showFile, onSubmit, onFile }) => {
  const inputRef = useRef();
  const { socket } = useContext(SocketContext);
  const { auth } = useContext(AuthContext);
  const [writing, setWriting] = useState(false);
  const [message, setMessage] = useState("");
  const [openEmoji, setOpenEmoji] = useState(false);

  useEffect(() => {
    if (writing) socket?.emit("WRITING", { ...auth, writing: true });
    else socket?.emit("WRITING", { ...auth, writing: false });
  }, [writing, auth, socket]);

  const handlerSubmit = (event) => {
    event.preventDefault();
    if (message.trim() && onSubmit) {
      socket?.emit("WRITING", { ...auth, writing: false });
      onSubmit(message.trim());
      setOpenEmoji(false);
      setMessage("");
    }
  };

  const handlerEmoji = (emoji) => {
    setMessage((old) => old + emoji.native);
    inputRef.current.focus();
  };

  const handlerChangeFile = (event) => {
    if (onFile) onFile(event);
  };

  return (
    <div className="tss_chat_footer_container bg-light flex-column">
      <Emojis show={showEmoji} open={openEmoji} onSelect={handlerEmoji} />
      <div className="tss_chat_footer bg-light">
        <ButtonEmoji
          show={showEmoji}
          open={openEmoji}
          onClick={() => setOpenEmoji((old) => !old)}
        />
        <InputFile show={showFile} onChangeFile={handlerChangeFile} />
        <form
          onSubmit={handlerSubmit}
          className="w-100 h-100 d-flex justify-content-center ms-2"
        >
          <input
            value={message}
            ref={inputRef}
            placeholder="Escribe un mensaje aquí"
            onChange={(event) => {
              if (event.target.value.trim().length > 0) setWriting(true);
              else setWriting(false);
              setMessage(event.target.value);
            }}
            className="form-control h-100 rounded-pill border-0"
          />
          <button type="submit" className="btn px-1 ms-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="#919191"
              className="bi bi-cursor-fill"
              viewBox="0 0 16 16"
            >
              <path d="M14.082 2.182a.5.5 0 0 1 .103.557L8.528 15.467a.5.5 0 0 1-.917-.007L5.57 10.694.803 8.652a.5.5 0 0 1-.006-.916l12.728-5.657a.5.5 0 0 1 .556.103z" />
            </svg>
          </button>
        </form>
      </div>
    </div>
  );
};

export default Footer;
