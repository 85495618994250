import React from "react";
import { Picker } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";

const Emojis = ({ show, open, onSelect }) => {
  if (open && show)
    return (
      <Picker
        style={{
          width: "100%",
          borderRadius: 0,
          borderBottom: 0,
          border: "0px",
          zIndex: 99,
          backgroundColor: "var(--bs-light)",
        }}
        showPreview={false}
        showSkinTones={false}
        emoji="shrug"
        perLine={2}
        native={true}
        set="facebook"
        color="#646363"
        include={[
          "people",
          "nature",
          "foods",
          "activity",
          "places",
          "objects",
          "symbols",
        ]}
        i18n={{
          search: "Buscar emoji",
          clear: "Limpiar",
          notfound: "No se encontro Emoji",
          skintext: "Elige tu tono de piel predeterminado",
          categories: {
            search: "Resultados de la búsqueda",
            recent: "Usado frecuentemente",
            people: "Emoticonos y Gente",
            nature: "Animales y naturaleza",
            foods: "Comida y bebida",
            activity: "Actividad",
            places: "Viajes y lugares",
            objects: "Objetos",
            symbols: "Los símbolos",
            flags: "Banderas",
            custom: "Personalizado",
          },
          categorieslabel: "Categorías de emoji",
          skintones: {
            1: "Tono de piel predeterminado",
            2: "Tono De Piel Claro",
            3: "Tono De Piel Claro Medio",
            4: "Tono De Piel Medio",
            5: "Tono De Piel Oscuro Medio",
            6: "Tono de piel oscuro",
          },
        }}
        onSelect={onSelect}
      />
    );
  else return null;
};

export const Button = ({ show, open, onClick }) => {
  if (show)
    return (
      <button className="btn px-1 cursor-pointer" onClick={onClick}>
        {open ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            fill="#919191"
            className="bi bi-x"
            viewBox="0 0 16 16"
          >
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            fill="#919191"
            className="bi bi-emoji-laughing"
            viewBox="0 0 16 16"
          >
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path d="M12.331 9.5a1 1 0 0 1 0 1A4.998 4.998 0 0 1 8 13a4.998 4.998 0 0 1-4.33-2.5A1 1 0 0 1 4.535 9h6.93a1 1 0 0 1 .866.5zM7 6.5c0 .828-.448 0-1 0s-1 .828-1 0S5.448 5 6 5s1 .672 1 1.5zm4 0c0 .828-.448 0-1 0s-1 .828-1 0S9.448 5 10 5s1 .672 1 1.5z" />
          </svg>
        )}
      </button>
    );
  else return null;
};

export default Emojis;
