const App = {
  company_name: "TSS",
  name: " Attento",
  version: "0.2.0",
  showEmojis: true,
  showButtonFile: true,
  ShowDropFile: true,
  ShowButtonMenu: true,
  requestWithAuth: false,
  onlyAuth: true,
  tc: "https://www.ibm.com/privacy/co/es/?lnk=flg-priv-ares",
  reCaptchaKey: "6LfAYwAoAAAAAOAVzkAdGN-qdDz47ppfSROST_c7",
  reCaptchaKeyLang: "es",
};

const Keys = {
  ket_storage: ".@",
};

// https://chat-attento-api.mybluemix.net
// http://localhost:8080

const Services = {
  Api: "https://proteccion-chat-api.16p9yr62tual.us-east.codeengine.appdomain.cloud/api",
  Socket: "https://proteccion-chat-api.16p9yr62tual.us-east.codeengine.appdomain.cloud",
  time_request: 200000,
  redirectAgent: "https://attentoagente.ibmsmartservices.com/login",
  //ssoMicrosoft:
  //  "https://chat-attento-api.mybluemix.net/api/v1/auth/openid/login",
    ssoMicrosoft:
    "/",
};

export { App, Services, Keys };
