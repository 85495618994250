import React from "react";
import { State, Type } from "./components";

const Message = ({ value, handlerClickTypeButton }) => {
  switch (value.from_model) {
    case "agents":
      return (
        <div className="tss-chant-container d-flex justify-content-start position-relative mb-2 mt-2 px-5">
          <div className="tss_chat_body_message_left shadow-sm">
            <span className="tss_chat_body_message_span_left"></span>
            <div className="p-1">
              <Type
                message={value}
                handlerClickTypeButton={handlerClickTypeButton}
              />
            </div>
            <State state={value.status} timestamp={value.created_at} />
          </div>
        </div>
      );
    case "users":
      return (
        <div className="tss-chant-container d-flex justify-content-end position-relative mb-2 mt-2 px-5">
          <div className="tss_chat_body_message_right shadow-sm">
            <span className="tss_chat_body_message_span_right"></span>
            <div className="p-1">
              <Type message={value} />
            </div>
            <State state={value.status} timestamp={value.created_at} />
          </div>
        </div>
      );
    default:
      return (
        <div className="tss-chant-container d-flex justify-content-center position-relative mb-2 mt-2 px-5">
          <div className="tss-chat-info shadow-sm">
            <p className="mb-0 px-2">{value.body}</p>
          </div>
        </div>
      );
  }
};

export default Message;
