export const types = {
  EDIT_TO: "[CHAT] edit to",
  EDIT_AREA: "[CHAT] edit area",
  EDIT_ROOM: "[CHAT] edit room",
  EDIT_QUEUE: "[CHAT] edit queue",
  ADD_MESSAGE: "[CHAT] add message",
  ADD_MESSAGES: "[CHAT] add messages",
  EDIT_MESSAGE: "[CHAT] edit message",
  EDIT_SESSION_ASSISTANT: "[CHAT] edit session assistant",
  SHOW_SURVEY: "[CHAT] Show survey",
  HIDE_SURVEY: "[CHAT] Hide survey",
  SET_WRITING: "[CHAT] Set writing",
};
