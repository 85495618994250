import React, { useContext, useEffect } from "react";
import { AuthContext } from "../context/AuthContext";
import { useParams } from "react-router-dom";
import { save_token } from "../../storage";

const W3 = () => {
  const { token } = useParams();
  const { login } = useContext(AuthContext);

  useEffect(() => {
    save_token(token);
    window.location.href = "/";
  }, [token, login]);

  return (
    <div className="w-100 h-100 d-flex justify-content-center align-items-center">
      <div
        className="spinner-border text-secondary"
        style={{ width: "3rem", height: "3rem" }}
        role="status"
      >
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};

export default W3;
