import React, { createContext, useContext, useEffect, useState } from "react";
import { Services as ServiceConfig } from "../../config";
import { AuthContext } from "./AuthContext";
import { useSocket } from "../hooks";
import { ChatContext } from "./ChatContext";
import { remove_token } from "../../storage.js";
import AudioMessage from "../assets/message.mp3";

export const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const [audio] = useState(new Audio(AudioMessage));
  const { auth } = useContext(AuthContext);
  const {
    state,
    addMessage,
    editQueue,
    editTo,
    showSurvey,
    setWriting,
  } = useContext(ChatContext);

  const { socket, online, onConnect, onDisconnect } = useSocket(
    ServiceConfig.Socket
  );

  useEffect(() => {
    if (auth.logged) onConnect();
  }, [auth, onConnect]);

  useEffect(() => {
    if (!auth.logged) onDisconnect();
  }, [auth, onDisconnect]);

  useEffect(() => {
    socket?.on("ON_MESSAGE", (payload) => {
      if (payload.from_model !== "users") {
        addMessage(payload);
        if (audio)
          audio
            .play()
            .then()
            .catch(() => console.log("No audio"));
      }
    });
  }, [socket, addMessage, audio]);

  useEffect(() => {
    socket?.on("ON_QUEUE_RELEASE", (payload) => {
      editTo(payload);
      editQueue({});
    });
  }, [socket, editQueue, editTo]);

  useEffect(() => {
    socket?.on("ON_CLOSE", () => {
      socket.emit("LEAVE_ROOM", { room: state.room.id });
      remove_token();
      showSurvey();
    });
  }, [socket, showSurvey, state.room.id]);

  useEffect(() => {
    socket?.on("ON_WRITING", (payload) => {
      if (payload.profile === "Agent") setWriting(payload.writing);
    });
  }, [socket, setWriting]);

  useEffect(() => {
    socket?.on("ON_TRANSFER", (payload) => {
      if (`${state.room.id}` === `${payload.room_id}`) {
        socket.emit("RELEASE_QUEUE");
        editQueue(payload);
      }
    });
  }, [socket, state.room.id, editQueue]);

  return (
    <SocketContext.Provider value={{ socket, online }}>
      {children}
    </SocketContext.Provider>
  );
};
