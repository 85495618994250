import React, { createContext, useCallback, useReducer } from "react";
import { appReducer } from "./appReducer";
import { types } from "./types";

export const AppContext = createContext();

const initial_state = {
  loading: true,
};

export const AppProvider = ({ children }) => {
  const [app, dispatch] = useReducer(appReducer, initial_state);

  const setLoading = useCallback((value) => {
    dispatch({ type: types.LOADING, payload: value });
  }, []);

  return (
    <AppContext.Provider value={{ app, setLoading }}>
      {children}
    </AppContext.Provider>
  );
};
