import React, { createContext, useCallback, useEffect, useState } from "react";

export const LineContext = createContext();

export const LineProvider = ({ children }) => {
  const [line, setLine] = useState(true);

  const handleLine = useCallback((event) => {
    if (event.type === "online") setLine(true);
    else if (event.type === "offline") setLine(false);
  }, []);

  useEffect(() => {
    window.addEventListener("online", handleLine);
    window.addEventListener("offline", handleLine);

    return () => {
      window.removeEventListener("online", handleLine);
      window.removeEventListener("offline", handleLine);
    };
  }, [handleLine]);

  return (
    <LineContext.Provider value={{ line }}>{children}</LineContext.Provider>
  );
};
