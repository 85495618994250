import React from "react";

const Lists = ({ message }) => {
  return (
    <>
      {JSON.parse(message.body).map((value, key) => (
        <div key={key} className="pb-3 pt-3">
          {Object.keys(value).map((key, index) => (
            <p key={key + index} className={`m-0`}>
              <strong>{key}: </strong>
              {value[key]}
            </p>
          ))}
        </div>
      ))}
    </>
  );
};

export default Lists;
