import React from "react";

const List = ({ message }) => {
  return (
    <>
      {Object.keys(JSON.parse(message.body)).map((key, index) => (
        <p key={index} className={`m-0`}>
          <strong>{key}: </strong>
          {JSON.parse(message.body)[key]}
        </p>
      ))}
    </>
  );
};

export default List;
