import React from "react";
import "moment/locale/es";
import { types } from "../types";
import Moment from "react-moment";
import moment from "moment-timezone";

const State = ({ state, timestamp }) => {
  const Icon = () => {
    switch (state) {
      case types.WAITTING:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            fill="#6c757d"
            viewBox="0 0 16 16"
            className="bi bi-clock me-1"
          >
            <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
          </svg>
        );
      case types.SEND:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            fill="#6c757d"
            viewBox="0 0 16 16"
            className="bi bi-check2 me-1"
          >
            <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
          </svg>
        );
      case types.SAVE:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            fill="#6c757d"
            viewBox="0 0 16 16"
            className="bi bi-check2-all me-1"
          >
            <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l7-7zm-4.208 7l-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0z" />
            <path d="M5.354 7.146l.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708z" />
          </svg>
        );
      default:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            fill="#6c757d"
            viewBox="0 0 16 16"
            className="bi bi-clock me-1"
          >
            <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
          </svg>
        );
    }
  };

  return (
    <span
      className="text-muted px-1 d-flex align-items-center justify-content-end"
      style={{ fontSize: 12 }}
    >
      <Icon state={state} />
      <Moment
        fromNow
        withTitle
        locale="es"
        format="D MMM YYYY HH:mm:s"
        fromNowDuring={700000}
      >
        {timestamp
          ? moment
              .utc(timestamp)
              .tz("America/Bogota")
              .format("YYYY-MM-DD HH:mm:s")
          : Date.now()}
      </Moment>
    </span>
  );
};

export default State;
