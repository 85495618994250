import React, { useContext, useState } from "react";
import { Logout, Line } from "../components";
import Logo from "../assets/images/logo.png";
import Survey from "../Survey";
import Chat from "../Chat";
import Api from "../Api";
import { remove_token } from "../../storage";
import { ChatContext } from "../context/ChatContext";

const Home = () => {
  const [api] = useState(new Api());
  const { state, showSurvey } = useContext(ChatContext);

  const hanlderClick = () => {
    api
      .close_room()
      .then(() => {
        remove_token();
        showSurvey();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="tss_container flex-column bg-white">
      <Line />
      <div className="tss_header_brand text-white bg-primary d-flex justify-content-between">
        <img src={Logo} height="100%" alt="logo" />
        <Logout onClick={hanlderClick} />
      </div>
      <div className="tss_chat">
        <Chat />
      </div>
      <Survey show={state.survey ? state.survey : false} />
    </div>
  );
};

export default Home;
