import React, { useContext, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import Home from "../Home";
import Auth from "../Auth";
import AuthRoute from "./AuthRoute";
import { Line } from "../components";
import PrivateRoute from "./PrivateRoute";
import { AuthContext } from "../context/AuthContext";
import { AppContext } from "../context/AppContext";
import { ChatContext } from "../context/ChatContext";
import { remove_token } from "../../storage";
import Survey from "../Survey";
import RedirectAgent from "./RedirectAgent";
import Api from "../Api";
import { App } from "../../config";
import SSO from "../SSO";

function AppRouter() {
  const { app } = useContext(AppContext);
  const { auth, validAuth } = useContext(AuthContext);
  const { state, showSurvey } = useContext(ChatContext);

  useEffect(() => {
    validAuth();
  }, [validAuth]);

  const handlerCloseRoom = (event) => {
    event.preventDefault();
    new Api()
      .close_room()
      .then(() => {
        remove_token();
        showSurvey();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  if (state.queue.id)
    return (
      <>
        <Line />
        <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center px-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            fill="var(--bs-gray)"
            className="bi bi-clock"
            viewBox="0 0 16 16"
          >
            <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
          </svg>
          <p className="mt-3 text-center">
            Te estamos transfiriendo a un asesor, por favor espera.
          </p>
          <button onClick={handlerCloseRoom} className="btn btn-primary mt-5">
            Finalizar conversación
          </button>
          {/* <p className="fw-bold text-center">
            Tu puesto en la fila es {state.queue.position}
          </p> */}
        </div>
        <Survey show={state.survey ? state.survey : false} />
      </>
    );

  if (app.loading)
    return (
      <div className="w-100 h-100 d-flex justify-content-center align-items-center">
        <div
          className="spinner-border text-secondary"
          style={{ width: "3rem", height: "3rem" }}
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );

  return (
    <Router>
      <Switch>
        <Route exact path="/callback/:token">
          <SSO />
        </Route>
        <PrivateRoute isAuth={auth.logged} exact path="/">
          <Home />
        </PrivateRoute>
        <AuthRoute isAuth={auth.logged} exact path="/login">
          <GoogleReCaptchaProvider
            reCaptchaKey={App.reCaptchaKey}
            language={App.reCaptchaKeyLang}
            scriptProps={{
              async: false,
              defer: false,
              appendTo: "head",
              nonce: undefined,
            }}
          >
            <Auth />
          </GoogleReCaptchaProvider>
        </AuthRoute>
        <Route exact path="/agent/login">
          <RedirectAgent />
        </Route>
        <Route path="*">
          <Redirect
            to={{
              pathname: "/",
            }}
          />
        </Route>
      </Switch>
    </Router>
  );
}

export default AppRouter;
