import { useEffect, useState, useCallback } from "react";
import { get_token } from "../../storage";
import io from "socket.io-client";

const useSocket = (server) => {
  const [socket, setSocket] = useState(null);
  const [online, setOnline] = useState(false);

  useEffect(() => {
    setOnline(socket?.connected || false);
  }, [socket]);

  useEffect(() => {
    socket?.on("connect", () => {
      setOnline(true);
    });
  }, [socket]);

  useEffect(() => {
    socket?.on("disconnect", () => {
      setOnline(false);
    });
  }, [socket]);

  const onConnect = useCallback(() => {
    setSocket(
      io.connect(server, {
        transports: ["websocket"],
        autoConnect: true,
        forceNew: true,
        auth: {
          token: get_token(),
        },
      })
    );
  }, [server]);

  const onDisconnect = useCallback(() => {
    socket?.disconnect();
  }, [socket]);

  return {
    socket,
    online,
    onConnect,
    onDisconnect,
  };
};

export default useSocket;
