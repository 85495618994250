import React, { useState, useContext, useEffect } from "react";
import Logo from "../../assets/images/logoLogin.png";
import { App as AppConfig } from "../../../config";
import { AlertMessage } from "../../components";
import { AuthContext } from "../../context/AuthContext";
import Api from "../../Api";

const RegisterAuth = ({ children }) => {
  const init_form = {
    uid: "",
    fullname: "",
    email: "",
    phone_number: "",
    area: "",
  };

  const [api] = useState(new Api());
  const authContext = useContext(AuthContext);
  const [form, setForm] = useState(init_form);
  const [tc, setTC] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [areas, setAreas] = useState([]);

  const handlerChangInput = (event) => {
    setForm((old) => ({
      ...old,
      [event.target.name]: event.target.value,
    }));
  };

  useEffect(() => {
    api
      .get_areas()
      .then((success) => {
        setAreas(success.data.areas);
      })
      .catch((err) => {
        ////console.log(err);
        setError(
          `[${err.status}] Se ha presentado un error en la aplicación, intenta nuevamente, gracias.`
        );
      });
  }, [api]);

  const handlerSubmit = (event) => {
    event.preventDefault();
    setError("");
    setLoading(true);
    api
      .requestAuth(
        form.uid,
        form.fullname,
        form.area,
        form.email,
        form.phone_number
      )
      .then((success) => {
        setLoading(false);
        authContext.login(success.data.token, success.data.user);
      })
      .catch((err) => {
        ////console.log(err);
        if (err.status === 401)
          setError(
            "El usuario se encuentra registrado con usuario de red, ingresa desde el formulario de usuario de red, gracias."
          );
        else if (err.status === 403 || err.status === 401)
          setError("El estado del usuario esta INACTIVO.");
        else
          setError(
            `[${err.status}] Se ha presentado un error en la aplicación, intenta nuevamente, gracias.`
          );
        setLoading(false);
      });
  };

  const disabledSubmit = () =>
    form.uid.length > 3 &&
    form.fullname.length > 3 &&
    form.area.length >= 1 &&
    form.phone_number.length > 3 &&
    form.email.length > 3 &&
    tc
      ? false
      : true;

  return (
    <div style={{ maxWidth: 370 }} className="p-3">
      <div className="d-flex justify-content-center align-items-center">
        <img src={Logo} alt="Logo" className="mb-5" style={{ maxWidth: 200 }} />
      </div>
      <form onSubmit={handlerSubmit}>
        <AlertMessage type="danger" show={error}>
          {error}
        </AlertMessage>
        <div className="mb-3">
          <input
            type="text"
            name="uid"
            value={form.uid}
            onChange={handlerChangInput}
            placeholder="Documento de identidad"
            className="form-control border-pill py-2"
            required
            autoFocus
          />
        </div>
        <div className="mb-3">
          <input
            type="text"
            name="fullname"
            value={form.fullname}
            onChange={handlerChangInput}
            placeholder="Nombre completo"
            className="form-control border-pill py-2"
            required
          />
        </div>
        <div className="mb-3">
          <input
            type="email"
            name="email"
            value={form.email}
            onChange={handlerChangInput}
            placeholder="Correo electrónico"
            className="form-control border-pill py-2"
            required
          />
        </div>
        <div className="mb-3">
          <input
            type="text"
            name="phone_number"
            value={form.phone_number}
            onChange={handlerChangInput}
            placeholder="Numero telefónico"
            className="form-control border-pill py-2"
            required
          />
        </div>
        <div className="mb-3">
          <select
            name="area"
            value={form.area}
            className="form-select"
            onChange={handlerChangInput}
            aria-label="Default select example"
            required
          >
            <option value="">Selecciona un area de servicio</option>
            {areas.map((value, index) => (
              <option key={index} value={value.id}>
                {value.name}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-3 form-check">
          <input
            type="checkbox"
            id="checktcrequest"
            className="form-check-input"
            checked={tc}
            onChange={() => setTC((old) => !old)}
            required
          />
          <label
            htmlFor="checktcrequest"
            className="form-check-label"
            style={{ fontSize: 13 }}
          >
            Acepto los&nbsp;
            <a
              target="_blank"
              rel="noreferrer"
              href={AppConfig.tc}
              className="text-decoration-none"
            >
              terminos y condiciones
            </a>
            &nbsp;para el tratamiento de mis datos.
          </label>
        </div>
        <div className="d-grid gap-2">
          <button
            disabled={disabledSubmit()}
            type="submit"
            className="btn btn-primary mt-3 text-white py-2"
          >
            {loading ? (
              <>
                Ingresando&nbsp;
                <div
                  className="spinner-border spinner-border-sm text-light"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              </>
            ) : (
              "INGRESAR"
            )}
          </button>
        </div>
        {children}
      </form>
    </div>
  );
};

export default RegisterAuth;
