import React from "react";
import { useDropzone } from "react-dropzone";

const DragFile = ({ show, className, style, onDrop, children }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop: onDrop,
  });

  if (show || false)
    return (
      <div className={className} style={style} {...getRootProps()}>
        <input {...getInputProps()} />
        {children}
        <div
          style={{
            zIndex: "999",
            transition: "all .11s ease",
            opacity: `${isDragActive ? 1 : 0}`,
            visibility: `${isDragActive ? "visible" : "hidden"}`,
          }}
          className="h-100 w-100 position-absolute bg-light"
        >
          <div className="w-100 h-100 d-flex flex-column position-relative">
            <div
              className="w-100 h-100 d-flex p-5"
              style={{
                backgroundColor: "var(--tss-light)",
              }}
            >
              <div
                className="w-100 d-flex justify-content-center align-items-center "
                style={{
                  borderColor: "var(--tss-light-dark)",
                  borderStyle: "dashed",
                  borderWidth: 3,
                }}
              >
                <h5 className="text-secondary">Arrastra tu documento aquí</h5>
              </div>
            </div>
            <div
              className="w-100 bg-light position-relative"
              style={{ height: "20%" }}
            >
              <div
                className="bg-primary text-white d-flex justify-content-center align-items-center shadow"
                style={{
                  borderRadius: "100%",
                  position: "absolute",
                  height: 50,
                  right: 35,
                  width: 50,
                  top: -25,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="var(--bs-white)"
                  className="bi bi-cursor-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M14.082 2.182a.5.5 0 0 1 .103.557L8.528 15.467a.5.5 0 0 1-.917-.007L5.57 10.694.803 8.652a.5.5 0 0 1-.006-.916l12.728-5.657a.5.5 0 0 1 .556.103z" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  else
    return (
      <div className={className} style={style}>
        {children}
      </div>
    );
};

export default DragFile;
