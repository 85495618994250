import React from "react";
import { SocketProvider } from "./context/SocketContext";
import { AuthProvider } from "./context/AuthContext";
import { LineProvider } from "./context/LineContext";
import { ChatProvider } from "./context/ChatContext";
import { AppProvider } from "./context/AppContext";
import { AppRouter } from "./router";

function App() {
  return (
    <LineProvider>
      <AppProvider>
        <AuthProvider>
          <ChatProvider>
            <SocketProvider>
              <AppRouter />
            </SocketProvider>
          </ChatProvider>
        </AuthProvider>
      </AppProvider>
    </LineProvider>
  );
}

export default App;
