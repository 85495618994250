import React, { useCallback, useContext, useEffect, useState } from "react";
import Body from "./Body";
import Header from "./Header";
import Footer from "./Footer";
import { App as AppConfig } from "../../config";
import BGImage from "../assets/images/BGChat.jpg";
import { FloatMenu, DragFile } from "./components";
import { ChatContext } from "../context/ChatContext";
import { AuthContext } from "../context/AuthContext";
import { Services as ServiceConfig } from "../../config";
import { types as typesMessage } from "./Messages/types";
import AudioMessage from "../assets/message.mp3";
import { get_token } from "../../storage";
import Api from "../Api";
import axios from "axios";

const Chat = () => {
  const [api] = useState(new Api());
  const [flag, setFlag] = useState(true);
  const { auth } = useContext(AuthContext);
  const [audio] = useState(new Audio(AudioMessage));
  const { state, addMessage, editMessage, editSessionAssistant } =
    useContext(ChatContext);

  const requestSessionAssistant = useCallback(() => {
    api
      .watson_assistant_request_session()
      .then((success) => {
        editSessionAssistant(success.data.session_id);
      })
      .catch((err) => {
        //console.log(err);
      });
  }, [api, editSessionAssistant]);

  useEffect(() => {
    if (
      flag &&
      state.to.bot &&
      state.messages.length > 0 &&
      state.room.status === "OPEN"
    ) {
      setFlag(false);
      requestSessionAssistant();
    }
  }, [
    flag,
    state.to.bot,
    state.room.status,
    state.messages.length,
    requestSessionAssistant,
  ]);

  useEffect(() => {
    if (
      flag &&
      state.to.bot &&
      state.messages.length <= 0 &&
      state.room.status === "OPEN"
    ) {
      setFlag(false);
      api
        .watson_assistant_init()
        .then((success) => {
          success.data.messages.map((value) => {
            return addMessage({
              ...value,
            });
          });
          editSessionAssistant(success.data.user_id);
          if (audio)
            audio
              .play()
              .then()
              .catch(() => console.log("No audio"));
        })
        .catch((err) => {
          //console.log(err);
        });
    }
  }, [
    api,
    flag,
    audio,
    state.to,
    state.room,
    addMessage,
    state.messages,
    editSessionAssistant,
  ]);

  const handlerMessage = (event) => {
    const _id = `${Date.now()}`;
    addMessage({
      id: _id,
      read: true,
      body: event,
      type: "text",
      from: auth.id,
      created_at: "",
      from_model: "users",
      room_id: state.room.id,
      status: typesMessage.WAITTING,
    });
    if (state.to.bot) {
      api
        .watson_assistant_message(state.session_assistant, event)
        .then((success) => {
          const _array = success.data.messages;
          editMessage({
            _id: _id,
            ..._array[0],
          });
          _array.shift();
          _array.map((value) => {
            return addMessage({
              ...value,
            });
          });
          if (audio)
            audio
              .play()
              .then()
              .catch(() => console.log("No audio"));
          if (success.data.transfer) handlerTransferRoom();
        })
        .catch((error) => {
          if (
            error.status === 404 &&
            error.data.message === "Invalid Session"
          ) {
            setFlag(false);
          }
          console.log(error);
        });
    } else {
      api
        .save_message(state.room.id, "users", auth.id, "text", event, true)
        .then((success) => {
          editMessage({
            _id: _id,
            ...success.data,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handlerTransferRoom = () => {
    api
      .put_room_transfer(state.area.id)
      .then(() => {
        console.log("User: Transfer");
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  const handlerFile = (event) => {
    handlerOnDrop([...event]);
  };

  const handlerOnDrop = (event) => {
    if (event.length > 1)
      return alert(
        `Se excedio el numero maximo de documentos, recuerda que solo se puede enviar de a un documento, gracias.`
      );

    if (event[0].size > 9437184)
      return alert(
        `No se pudo enviar el archivo ya que el tamaño máximo es de 9MB y el documnto pesa ${(
          event.size / 1048576
        ).toFixed(2)}MB.`
      );

    const ext = event[0].name
      .slice(((event[0].name.lastIndexOf(".") - 1) >>> 0) + 2)
      .toLowerCase();

    if (
      ext === "pdf" ||
      ext === "pptx" ||
      ext === "docx" ||
      ext === "xlsx" ||
      ext === "xlsm" ||
      ext === "jpeg" ||
      ext === "jpg" ||
      ext === "png"
    ) {
    } else return alert(`El tipo de documento que desea subir no es válido.`);

    const _id = `${Date.now()}`;
    addMessage({
      id: _id,
      read: true,
      body: "Subiendo documento ...",
      type: "file",
      from: auth.id,
      created_at: "",
      from_model: "users",
      room_id: state.room.id,
      status: typesMessage.WAITTING,
    });

    if (state.to.bot) {
      const formData = new FormData();
      formData.append("body", "file");
      formData.append("upload", true);
      formData.append("file", event[0]);
      formData.append("sessionId", state.session_assistant);
      axios
        .post(`${ServiceConfig.Api}/watson/assistant/messages/file`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "Api-key": `${get_token()}`,
          },
        })
        .then((success) => {
          const _array = success.data.messages;
          editMessage({
            _id: _id,
            ..._array[0],
          });
          _array.shift();
          _array.map((value) => {
            return addMessage({
              ...value,
            });
          });
          if (audio)
            audio
              .play()
              .then()
              .catch(() => console.log("No audio"));
          if (success.data.transfer) handlerTransferRoom();
        })
        .catch((error) => {
          editMessage({
            _id: _id,
            body: "Error en la subida.",
          });
          console.log(error);
        });
    } else {
      const formData = new FormData();
      formData.append("upload", true);
      formData.append("file", event[0]);
      formData.append("roomId", state.room.id);
      formData.append("fromModel", "users");
      formData.append("from", auth.id);
      formData.append("type", "file");
      formData.append("body", "file");
      formData.append("read", true);
      axios
        .post(`${ServiceConfig.Api}/messages/file`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "Api-key": `${get_token()}`,
          },
        })
        .then((success) => {
          editMessage({
            _id: _id,
            ...success.data,
          });
        })
        .catch((error) => {
          editMessage({
            _id: _id,
            body: "Error en la subida.",
          });
          console.log(error);
        });
    }
  };

  return (
    <DragFile
      show={AppConfig.ShowDropFile}
      onDrop={handlerOnDrop}
      className="w-100 h-100 d-flex flex-column position-relative"
      style={{
        backgroundSize: "contain",
        backgroundImage: `url(${BGImage})`,
      }}
    >
      <Header />
      <Body handlerClickTypeButton={handlerMessage} />
      <Footer
        onSubmit={handlerMessage}
        onFile={handlerFile}
        showEmoji={AppConfig.showEmojis}
        showFile={AppConfig.showButtonFile}
      />
      <FloatMenu
        show={AppConfig.ShowButtonMenu && state.to.bot}
        onClick={() => handlerMessage("Menu")}
      />
    </DragFile>
  );
};

export default Chat;
