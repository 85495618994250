import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";
import { save_token, get_token, remove_token } from "../../../storage";
import { authReducer } from "./authReducer";
import { types } from "./types";
import Api from "../../Api";
import { AppContext } from "../AppContext";

export const AuthContext = createContext();

const initial_state = {
  id: null,
  fullname: null,
  email: null,
  profile: null,
  room: null,
  logged: false,
};

export const AuthProvider = ({ children }) => {
  const [auth, dispatch] = useReducer(authReducer, initial_state);
  const { setLoading } = useContext(AppContext);

  const login = (token) => {
    save_token(token);
    validAuth();
  };

  const logout = useCallback(() => {
    remove_token();
    dispatch({ type: types.LOGOUT });
    setLoading(false);
  }, [setLoading]);

  const validAuth = useCallback(() => {
    setLoading(true);
    const token = get_token();
    if (!token) {
      dispatch({ type: types.LOGOUT });
      return setLoading(false);
    }
    new Api()
      .profile()
      .then((success) => {
        dispatch({
          type: types.LOGIN,
          payload: {
            id: success.data.id,
            fullname: success.data.fullname,
            email: success.data.email,
            profile: success.data.profile,
            room: success.data.data.room,
            logged: true,
          },
        });
      })
      .catch((err) => {
        //console.log(err);
        remove_token();
        dispatch({ type: types.LOGOUT });
        setLoading(false);
      });
  }, [setLoading]);

  return (
    <AuthContext.Provider value={{ auth, login, logout, validAuth }}>
      {children}
    </AuthContext.Provider>
  );
};
