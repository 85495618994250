import { types } from "./types";

export const chatReducer = (state, action) => {
  switch (action.type) {
    case types.EDIT_TO:
      return {
        ...state,
        to: action.payload,
      };
    case types.EDIT_AREA:
      return {
        ...state,
        area: action.payload,
      };
    case types.EDIT_ROOM:
      return {
        ...state,
        room: action.payload,
      };
    case types.EDIT_QUEUE:
      return {
        ...state,
        queue: action.payload,
      };
    case types.ADD_MESSAGE:
      return {
        ...state,
        messages: [...state.messages, action.payload],
      };
    case types.EDIT_MESSAGE:
      let _messages = [...state.messages];
      const i = _messages.findIndex(
        (value) => `${value.id}` === `${action.payload._id}`
      );
      _messages[i] = {
        ..._messages[i],
        ...action.payload,
      };
      return {
        ...state,
        messages: _messages,
      };
    case types.ADD_MESSAGES:
      return {
        ...state,
        messages: action.payload,
      };
    case types.EDIT_SESSION_ASSISTANT:
      return {
        ...state,
        session_assistant: action.payload,
      };
    case types.SHOW_SURVEY:
      return {
        ...state,
        survey: true,
      };
    case types.HIDE_SURVEY:
      return {
        ...state,
        survey: false,
      };
    case types.SET_WRITING:
      return {
        ...state,
        writing: action.payload,
      };
    default:
      return state;
  }
};
