import React, { useState } from "react";
import Logo from "../../assets/images/logoLogin.png";
import { App as AppConfig } from "../../../config";
import Api from "../../Api";
import { AlertMessage } from "../../components";

const Register = ({ children }) => {
  const init_form = {
    uid: "",
    fullname: "",
    email: "",
    phone_number: "",
    password: "",
  };

  const [api] = useState(new Api());
  const [form, setForm] = useState(init_form);
  const [tc, setTC] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [info, setInfo] = useState("");

  const handlerChangInput = (event) => {
    setForm((old) => ({
      ...old,
      [event.target.name]: event.target.value,
    }));
  };

  const handlerSubmit = (event) => {
    event.preventDefault();
    setError("");
    setInfo("");
    setLoading(true);
    api
      .requestUser(
        form.uid,
        form.fullname,
        form.password,
        form.email,
        form.phone_number
      )
      .then(() => {
        setInfo(
          `Solicitud enviada correctamente, se te notificara a tu correo ${form.email}.`
        );
        setForm(init_form);
        setLoading(false);
      })
      .catch((err) => {
        ////console.log(err);
        if (err.status === 409)
          setError("El usuario ya se encuentra registrado.");
        else
          setError(
            `[${err.status}] Se ha presentado un error en la aplicación, intenta nuevamente, gracias.`
          );
        setLoading(false);
      });
  };

  const disabledSubmit = () =>
    form.uid.length > 3 &&
    form.fullname.length > 3 &&
    form.email.length > 3 &&
    form.phone_number.length > 3 &&
    form.password.length > 3 &&
    tc
      ? false
      : true;

  return (
    <div style={{ maxWidth: 370 }} className="p-3">
      <div className="d-flex justify-content-center align-items-center">
        <img src={Logo} alt="Logo" className="mb-5" style={{ maxWidth: 200 }} />
      </div>
      <form onSubmit={handlerSubmit}>
        <AlertMessage type="danger" show={error}>
          {error}
        </AlertMessage>
        <AlertMessage type="info" show={info}>
          {info}
        </AlertMessage>
        <div className="mb-3">
          <input
            type="text"
            name="uid"
            value={form.uid}
            onChange={handlerChangInput}
            placeholder="Documento de identidad"
            className="form-control border-pill py-2"
            required
            autoFocus
          />
        </div>
        <div className="mb-3">
          <input
            type="text"
            name="fullname"
            value={form.fullname}
            onChange={handlerChangInput}
            placeholder="Nombre completo"
            className="form-control border-pill py-2"
            required
          />
        </div>
        <div className="mb-3">
          <input
            type="email"
            name="email"
            value={form.email}
            onChange={handlerChangInput}
            placeholder="Correo electrónico"
            className="form-control border-pill py-2"
            required
          />
        </div>
        <div className="mb-3">
          <input
            type="text"
            name="phone_number"
            value={form.phone_number}
            onChange={handlerChangInput}
            placeholder="Numero telefónico"
            className="form-control border-pill py-2"
            required
          />
        </div>
        <div className="mb-3">
          <input
            type="password"
            name="password"
            value={form.password}
            placeholder="Contraseña"
            onChange={handlerChangInput}
            className="form-control py-2"
            required
          />
        </div>
        <div className="mb-3 form-check">
          <input
            type="checkbox"
            id="checktcrequest"
            className="form-check-input"
            checked={tc}
            onChange={() => setTC((old) => !old)}
            required
          />
          <label
            htmlFor="checktcrequest"
            className="form-check-label"
            style={{ fontSize: 13 }}
          >
            Acepto los&nbsp;
            <a
              target="_blank"
              rel="noreferrer"
              href={AppConfig.tc}
              className="text-decoration-none"
            >
              terminos y condiciones
            </a>
            &nbsp;para el tratamiento de mis datos.
          </label>
        </div>
        <div className="d-grid gap-2">
          <button
            disabled={disabledSubmit()}
            type="submit"
            className="btn btn-primary mt-3 text-white py-2"
          >
            {loading ? (
              <>
                Solicitando&nbsp;
                <div
                  className="spinner-border spinner-border-sm text-light"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              </>
            ) : (
              "SOLICITAR USUARIO TEMPORAL"
            )}
          </button>
        </div>
        {children}
      </form>
    </div>
  );
};

export default Register;
