import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { Modal } from "bootstrap";
import Api from "../Api";
import { ChatContext } from "../context/ChatContext";


const Survey = ({ show }) => {
  const text =
    "Basados en la experiencia del servicio recibido, puntualidad, amabilidad y finalmente la solución brindada, ¿Recomendaría el servicio a sus compañeros?";

  const authContext = useContext(AuthContext);
  const { hideSurvey, editQueue } = useContext(ChatContext);
  const [api] = useState(new Api());
  const [modal, setModal] = useState(null);
  const [score, setScore] = useState(null);
  const [message, setMessage] = useState("");
  const [sending, setSending] = useState(false);

  useEffect(() => {
    setModal(
      new Modal(document.getElementById("surveyModal"), {
        backdrop: "static",
        keyboard: false,
      })
    );
  }, []);

  useEffect(() => {
    if (show) modal?.show();
  }, [show, modal]);

  const handlerSubmit = (event) => {
    event.preventDefault();
    setSending(true);
    
    api
      .save_survey(score, text, message)
      .then(() => {
        modal.hide();
        hideSurvey();
        authContext.logout();
        editQueue({});
      })
      .catch((error) => {
        setSending(true);
        console.log(error);
      });
      window.location.href = "https://portalserviciosproteccion.ibmsmartservices.com";
  };

  return (
    <div
      tabIndex="-1"
      id="surveyModal"
      aria-hidden="true"
      className="modal fade"
      aria-labelledby="surveyModalLabel"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content border-0">
          <div className="modal-header">
            <h5 className="modal-title" id="surveyModalCenterEndTitle">
              Conversación finalizada!
            </h5>
          </div>
          <form onSubmit={handlerSubmit}>
            <div className="modal-body">
            Nos gustaría conocer más sobre la experiencia que tuviste el día de hoy.
            ¿De acuerdo con su nivel de satisfacción qué tanto recomendaría nuestro servicio de soporte?
            Por favor marque una de las caras:
              <br />
              <br />
              <div className="form-group d-flex justify-content-between">
                <svg
                  style={{ cursor: "pointer" }}
                  onClick={() => setScore(10)}
                  width="15%"
                  height="15%"
                  xmlns="http://www.w3.org/2000/svg"
                  fill={score === 10 ? "#00F220" : "#313131"}
                  className="bi bi-emoji-laughing"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path d="M12.331 9.5a1 1 0 0 1 0 1A4.998 4.998 0 0 1 8 13a4.998 4.998 0 0 1-4.33-2.5A1 1 0 0 1 4.535 9h6.93a1 1 0 0 1 .866.5zM7 6.5c0 .828-.448 0-1 0s-1 .828-1 0S5.448 5 6 5s1 .672 1 1.5zm4 0c0 .828-.448 0-1 0s-1 .828-1 0S9.448 5 10 5s1 .672 1 1.5z" />
                </svg>
                {/* <svg
                  style={{ cursor: "pointer" }}
                  onClick={() => setScore(4)}
                  width="15%"
                  height="15%"
                  xmlns="http://www.w3.org/2000/svg"
                  fill={score === 4 ? "#FFF300" : "#313131"}
                  className="bi bi-emoji-smile"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path d="M4.285 9.567a.5.5 0 0 1 .683.183A3.498 3.498 0 0 0 8 11.5a3.498 3.498 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.498 4.498 0 0 1 8 12.5a4.498 4.498 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5z" />
                </svg> */}
                <svg
                  style={{ cursor: "pointer" }}
                  onClick={() => setScore(8)}
                  width="15%"
                  height="15%"
                  xmlns="http://www.w3.org/2000/svg"
                  fill={score === 8 ? "#FFB100" : "#313131"}
                  className="bi bi-emoji-neutral"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path d="M4 10.5a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1h-7a.5.5 0 0 0-.5.5zm3-4C7 5.672 6.552 5 6 5s-1 .672-1 1.5S5.448 8 6 8s1-.672 1-1.5zm4 0c0-.828-.448-1.5-1-1.5s-1 .672-1 1.5S9.448 8 10 8s1-.672 1-1.5z" />
                </svg>
               <svg
                  style={{ cursor: "pointer" }}
                  onClick={() => setScore(1)}
                  width="15%"
                  height="15%"
                  xmlns="http://www.w3.org/2000/svg"
                  fill={score === 1 ? "#FF4D00" : "#313131"}
                  className="bi bi-emoji-frown"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path d="M4.285 12.433a.5.5 0 0 0 .683-.183A3.498 3.498 0 0 1 8 10.5c1.295 0 2.426.703 3.032 1.75a.5.5 0 0 0 .866-.5A4.498 4.498 0 0 0 8 9.5a4.5 4.5 0 0 0-3.898 2.25.5.5 0 0 0 .183.683zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5z" />
                </svg> 
                {/* <svg
                  style={{ cursor: "pointer" }}
                  onClick={() => setScore(1)}
                  width="15%"
                  height="15%"
                  xmlns="http://www.w3.org/2000/svg"
                  fill={score === 1 ? "#CA0000" : "#313131"}
                  className="bi bi-emoji-angry"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path d="M4.285 12.433a.5.5 0 0 0 .683-.183A3.498 3.498 0 0 1 8 10.5c1.295 0 2.426.703 3.032 1.75a.5.5 0 0 0 .866-.5A4.498 4.498 0 0 0 8 9.5a4.5 4.5 0 0 0-3.898 2.25.5.5 0 0 0 .183.683zm6.991-8.38a.5.5 0 1 1 .448.894l-1.009.504c.176.27.285.64.285 1.049 0 .828-.448 1.5-1 1.5s-1-.672-1-1.5c0-.247.04-.48.11-.686a.502.502 0 0 1 .166-.761l2-1zm-6.552 0a.5.5 0 0 0-.448.894l1.009.504A1.94 1.94 0 0 0 5 6.5C5 7.328 5.448 8 6 8s1-.672 1-1.5c0-.247-.04-.48-.11-.686a.502.502 0 0 0-.166-.761l-2-1z" />
                </svg> */}
              </div>
              <br />
              <div className="form-group">
                <textarea
                  value={message}
                  onChange={(event) => setMessage(event.target.value)}
                  className="form-control shadow-sm bg-light"
                  placeholder="Dejanos un mensaje ..."
                  rows="3"
                  disabled={sending}
                ></textarea>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-primary shadow rounded-pill"
                disabled={sending}
              >
                ENVIAR
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Survey;
