import React, {
  createContext,
  useEffect,
  useReducer,
  useContext,
  useCallback,
} from "react";
import { AuthContext } from "../AuthContext";
import { chatReducer } from "./chatReducer";
import { AppContext } from "../AppContext";
import { types } from "./types";
import Api from "../../Api";

const initialState = {
  session_assistant: null,
  room: {},
  area: {},
  queue: {},
  to: {},
  messages: [],
  survey: false,
};

export const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const { auth, logout } = useContext(AuthContext);
  const { setLoading } = useContext(AppContext);
  const [state, dispatch] = useReducer(chatReducer, initialState);

  const getRoom = useCallback(() => {
    new Api()
      .get_room(auth.room)
      .then((success) => {
        const value = success.data.room;
        if (!value.id) return logout();
        if (value.status !== "OPEN") return logout();
        dispatch({
          type: types.EDIT_ROOM,
          payload: {
            id: value.id,
            status: value.status,
            created_at: value.created_at,
            updated_at: value.updated_at,
          },
        });
        dispatch({ type: types.EDIT_AREA, payload: value.area });
        dispatch({ type: types.EDIT_QUEUE, payload: value.queue });
        dispatch({ type: types.EDIT_TO, payload: value.agent });
        dispatch({
          type: types.ADD_MESSAGES,
          payload: value.messages,
        });
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        logout();
      });
  }, [auth.room, setLoading, logout]);

  useEffect(() => {
    if (auth.logged) {
      getRoom();
    }
  }, [auth.logged, getRoom]);

  const editArea = (payload) => {
    dispatch({
      type: types.EDIT_AREA,
      payload,
    });
  };

  const editTo = useCallback((payload) => {
    dispatch({
      type: types.EDIT_TO,
      payload,
    });
  }, []);

  const addMessage = useCallback((payload) => {
    dispatch({
      type: types.ADD_MESSAGE,
      payload,
    });
  }, []);

  const editQueue = useCallback((payload) => {
    dispatch({
      type: types.EDIT_QUEUE,
      payload,
    });
  }, []);

  const editMessage = (payload) => {
    dispatch({
      type: types.EDIT_MESSAGE,
      payload,
    });
  };

  const editSessionAssistant = useCallback((payload) => {
    dispatch({
      type: types.EDIT_SESSION_ASSISTANT,
      payload,
    });
  }, []);

  const showSurvey = useCallback(() => {
    dispatch({
      type: types.SHOW_SURVEY,
    });
  }, []);

  const hideSurvey = useCallback(() => {
    dispatch({
      type: types.HIDE_SURVEY,
    });
  }, []);

  const setWriting = useCallback((value) => {
    dispatch({
      type: types.SET_WRITING,
      payload: value,
    });
  }, []);

  return (
    <ChatContext.Provider
      value={{
        state,
        editTo,
        getRoom,
        editArea,
        editQueue,
        addMessage,
        setWriting,
        hideSurvey,
        showSurvey,
        editMessage,
        editSessionAssistant,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
