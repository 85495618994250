import React from "react";
import File from "./File";
import Link from "./Link";
import List from "./List";
import Text from "./Text";
import Html from "./Html";
import Image from "./Image";
import Video from "./Video";
import Lists from "./Lists";
import { types } from "./types";
import Buttons from "./Buttons";

const Type = ({ message, handlerClickTypeButton }) => {
  switch (message.type) {
    case types.TEXT:
      return <Text message={message} />;
    case types.HTML:
      return <Html message={message} />;
    case types.IMAGE:
      return <Image message={message} />;
    case types.VIDEO:
      return <Video message={message} />;
    case types.BUTTONS:
      return (
        <Buttons
          message={message}
          handlerClickTypeButton={handlerClickTypeButton}
        />
      );
    case types.FILE:
      return <File message={message} />;
    case types.LINK:
      return <Link message={message} />;
    case types.LIST:
      return <List message={message} />;
    case types.LISTS:
      return <Lists message={message} />;
    default:
      return <Text message={message} />;
  }
};

export default Type;
