import React, { useState, useContext, useEffect } from "react";
import Logo from "../../assets/images/logoLogin.png";
import { App as AppConfig } from "../../../config";
import { AuthContext } from "../../context/AuthContext";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { AlertMessage } from "../../components";
import Api from "../../Api";

const Login = ({ children }) => {
  const init_form = {
    username: "",
    password: "",
    area: "",
  };

  const recaptchaName = "login_page_chat_tuya";
  const [token, setToken] = useState(null);
  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    if (executeRecaptcha) {
      setTimeout(() => {
        setToken(executeRecaptcha(recaptchaName));
      }, 500);
    }
  }, [executeRecaptcha]);

  const authContext = useContext(AuthContext);
  const [api] = useState(new Api());
  const [form, setForm] = useState(init_form);
  const [tc, setTC] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [areas, setAreas] = useState([]);

  useEffect(() => {
    api
      .get_areas()
      .then((success) => {
        setAreas(success.data.areas.filter(areas => areas.id 
          > 3));
      })
      .catch((err) => {
        ////console.log(err);
        setError(
          `[${err.status}] Se ha presentado un error en la aplicación, intenta nuevamente, gracias.`
        );
      });
  }, [api]);

  const handlerChangInput = (event) => {
    setForm((old) => ({
      ...old,
      [event.target.name]: event.target.value,
    }));
  };

  const handlerSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    setError("");
    token
      .then((resultToken) => {
        api
          .login(form.username, form.password, form.area, resultToken)
          .then((success) => {
            setLoading(false);
            authContext.login(success.data.token, success.data.user);
          })
          .catch((err) => {
            ////console.log(err);
            setToken(executeRecaptcha(recaptchaName));
            if (err.status === 401)
              setError("Usuario o contraseña incorrecta.");
            else if (err.status === 403 || err.status === 401)
              setError(
                "El estado del usuario es INACTIVO ó PENDIENTE por aprobación."
              );
            else
              setError(
                `[${err.status}] Se ha presentado un error en la aplicación, intenta nuevamente, gracias.`
              );
            setLoading(false);
          });
      })
      .catch((error) => {
        //console.log(error);
        setLoading(false);
        setToken(executeRecaptcha(recaptchaName));
        setError("Ups, ocurrio un problema, intenta nuevamente.");
      });
  };

  const disabledSubmit = () =>
    form.username.length > 3 &&
    form.password.length > 3 &&
    form.area.length >= 1 &&
    tc &&
    !loading
      ? false
      : true;

  return (
    <div style={{ maxWidth: 370 }} className="p-3">
      <div className="d-flex justify-content-center align-items-center">
        <img src={Logo} alt="Logo" className="mb-5" style={{ maxWidth: 200 }} />
      </div>
      {
        <form onSubmit={handlerSubmit}>
          <AlertMessage type="danger" show={error}>
            {error}
          </AlertMessage>
          <div className="mb-3">
            <input
              type="text"
              name="username"
              value={form.username}
              placeholder="Usuario"
              onChange={handlerChangInput}
              className="form-control border-pill py-2"
              required
              autoFocus
            />
          </div>
          <div className="mb-3">
            <input
              type="password"
              name="password"
              value={form.password}
              placeholder="Contraseña"
              onChange={handlerChangInput}
              className="form-control py-2"
              required
            />
          </div>
          <div className="mb-3">
            <select
              name="area"
              value={form.area}
              className="form-select"
              onChange={handlerChangInput}
              aria-label="Default select example"
              required
            >
              <option value="">Selecciona un area de servicio</option>
              {areas.map((value, index) => (
                <option key={index} value={value.id}>
                  {value.name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-3 form-check">
            <input
              type="checkbox"
              id="checktclogin"
              className="form-check-input"
              checked={tc}
              onChange={() => setTC((old) => !old)}
              required
            />
            <label
              htmlFor="checktclogin"
              className="form-check-label"
              style={{ fontSize: 13 }}
            >
              Acepto los&nbsp;
              <a
                target="_blank"
                rel="noreferrer"
                href={AppConfig.tc}
                className="text-decoration-none"
              >
                terminos y condiciones
              </a>
              &nbsp;para el tratamiento de mis datos.
            </label>
          </div>
          <div className="d-grid gap-2">
            <button
              type="submit"
              disabled={disabledSubmit()}
              className="btn btn-primary mt-3 text-white py-2"
            >
              {loading ? (
                <>
                  Ingresando&nbsp;
                  <div
                    className="spinner-border spinner-border-sm text-light"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </>
              ) : (
                "INGRESAR"
              )}
            </button>
          </div>
          {children}
        </form>
      }
   {/*    <div className="d-grid gap-2" style={{ display: "none" }}>
        <a
          href={Services.ssoMicrosoft}
          className="btn btn-light mt-3 py-2 d-flex align-items-center justify-content-center shadow-sm"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="21"
            viewBox="0 0 21 21"
          >
            <title>MS-SymbolLockup</title>
            <rect x="1" y="1" width="9" height="9" fill="#f25022" />
            <rect x="1" y="11" width="9" height="9" fill="#00a4ef" />
            <rect x="11" y="1" width="9" height="9" fill="#7fba00" />
            <rect x="11" y="11" width="9" height="9" fill="#ffb900" />
          </svg>
          <span className="ms-2 text-dark">INICIAR SESIÓN</span>
        </a>
      </div> */}
    </div>
  );
};

export default Login;
