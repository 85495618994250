import React, { useState } from "react";
import Avatar from "../assets/images/watsonSpinSlow.gif";
import Login from "./Login";
import Register from "./Register";
import RegisterAuth from "./RegisterAuth";
import { App } from "../../config";

const Auth = () => {
  const [formLogin, setFormLogin] = useState(true);

  const changeform = (event) => {
    event.preventDefault();
    setFormLogin((current) => !current);
  };

  return (
    <div
      className="container-fluid h-100"
      style={{
        background:
          "linear-gradient(to bottom, var(--bs-primary) 0%, var(--bs-primary) 35%, var(--bs-light) 35%, var(--bs-light) 100% )",
      }}
    >
      <div className="row h-100">
        <div className="col h-100">
          <div className="w-100 h-100 d-flex justify-content-center align-items-center">
            <div className="card border-0 rounded">
              <div className="card-body rounded shadow d-flex p-0">
                <div className="d-none d-md-flex d-lg-flex flex-column justify-content-center align-items-center bg-light p-3">
                  <img src={Avatar} alt="Avatar" style={{ maxWidth: 250 }} />
                </div>
                {formLogin ? (
                  <Login>
                    <>
                      {!App.onlyAuth ? (
                        <div className="form-group d-flex justify-content-center align-items-center mt-3">
                          <a
                            href="/"
                            onClick={changeform}
                            style={{ fontSize: 14 }}
                            className="btn btn-link text-decoration-none text-dark"
                          >
                            No tengo usuario de red
                          </a>
                        </div>
                      ) : null}
                    </>
                  </Login>
                ) : (
                  <>
                    {!App.onlyAuth ? (
                      <>
                        {App.requestWithAuth ? (
                          <RegisterAuth>
                            <div className="form-group d-flex justify-content-center align-items-center mt-3">
                              <a
                                href="/"
                                onClick={changeform}
                                style={{ fontSize: 14 }}
                                className="btn btn-link text-decoration-none text-dark"
                              >
                                Ingresar con usuario de red
                              </a>
                            </div>
                          </RegisterAuth>
                        ) : (
                          <Register>
                            <div className="form-group d-flex justify-content-center align-items-center mt-3">
                              <a
                                href="/"
                                onClick={changeform}
                                style={{ fontSize: 14 }}
                                className="btn btn-link text-decoration-none text-dark"
                              >
                                Ingresar con usuario de red
                              </a>
                            </div>
                          </Register>
                        )}
                      </>
                    ) : null}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Auth;
