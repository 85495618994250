import React, { useContext, useEffect, useState } from "react";
import { ChatContext } from "../context/ChatContext";
import { LineContext } from "../context/LineContext";
import { SocketContext } from "../context/SocketContext";

const Line = () => {
  const { getRoom } = useContext(ChatContext);
  const [refresh, setRefresh] = useState(false);
  const { line } = useContext(LineContext);
  const { online } = useContext(SocketContext);

  useEffect(() => {
    if (line && online && refresh) {
      setRefresh(false);
      getRoom();
    }
    if (!line || (!online && !refresh)) setRefresh(true);
  }, [line, online, refresh, getRoom]);

  if (!line || !online)
    return (
      <div className="text-white bg-dark d-flex justify-content-center align-items-center px-2">
        <p className="mb-0 font-weight-bold" style={{ fontSize: 12 }}>
          Tu conexión se ha perdido, reconectando ...
        </p>
      </div>
    );
  else return null;
};

export default Line;
